@import "styles/global.scss";

.logo-container {
  margin-right: 0px;
}

.logo-image {
  width: 100px;
}
@media only screen and (max-width: 1405px) {
  .logo-image{
    width: 90px;
  }
}

@media only screen and (max-width: 1299px) {
  .logo-image{
    width: 80px;
  }
  
}

@media only screen and (max-width: 991px) {
  .logo-container {
    margin-right: 0;
  }

  .logo-image{
    width: 100px;
  }
}

@import "styles/global.scss";

.product-item::after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 1px);
  height: 100%;
  pointer-events: none;
  content: "";
  border: solid 2px rgba(235, 235, 235, 0);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-item:hover::after {
  box-shadow: 0 25px 29px rgba(63, 78, 100, 0.15);
  border: solid 2px rgba(235, 235, 235, 1);
}

.product {
  width: 100%;
  border: solid 1px $color-base-hover;
  cursor: pointer;
}

.product-image {
  width: 100% !important;
  height: 413px;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: 274px;
  max-height: 413px;
  object-fit: contain;
 
}

.product-info {
  text-align: center;
  padding: 20px 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 135px;
  width: 274px;
}

.product-name a {
  color: $color-black-text;
  line-height: 20px;
}

.product-price {
  font-size: 16px;
  color: $color-base;
  font-weight: 600;
}-qua

.product-price span {
  font-size: 12px;
  margin-left: 10px;
  color: $color-gray-light;
  text-decoration: line-through;
}

.product-info .product-price {
  font-size: 14px;
}



.product-quantity-selector {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 80%;
  height: 15%;
  background-color: $color-gray-dark;
  color: $color-white;
  font-size: 13px;
  border: 0.5px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0;
}

.product-image-icons:hover .product-quantity-selector{
  display:flex;
  opacity: 0.8;
}
.product-out-of-stock {
  text-align: center;
  color: $color-red;
}

.product-name:hover a {
  color: $color-gray-light;
}

.color-selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 5px;
  }
}

.favorito-heart{
  position: absolute;
  background: #FFFFFF;
  //background: #7cab5a;
  border-radius: 0px 0px 0px 8px;
  height: 31px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  text-align: center;
}

.image-heart-favourite-fill  {
  color: $color-base;
  height: 20px !important;
  border-radius: 0px !important;
}
.image-heart-favourite-empty  {
  color: $color-base;
  height: 20px !important;
  border-radius: 0px !important;
}
.heart-display {
  .image-heart-favourite-empty{
    display: block;
  }
  .image-heart-favourite-fill{
    display: none;
  }
}
.heart-display:hover {
  .image-heart-favourite-empty{
    display: none;
  }
  .image-heart-favourite-fill{
    display: block;
  }
}

.product-image-icons{
  display: flex;
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.product-image-icons button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 80%;
  height: 15%;
  background-color: $color-gray-dark;
  color: $color-white;
  font-size: 13px;
  border: 0.5px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0;
}

.product-image-icons:hover button{
  opacity: 0.8;
}

.combinacionesOferta{
  margin-top: 0px;
}

.color-circle.selected::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
  border: 2px solid #fff;
}
@media only screen and (max-width: 576px) {

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 10px;
    padding-bottom: 0px;
    width: 201px;
  }

  .product-quantity-selector {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
  }
  .image-heart-favourite {
    height: 20px !important;
  }
  .product-image {
    height: 303px;
  }
  
  .product-image img {
    max-width: 202px;
    max-height: 303px;
   
  }
}
@media only screen and (max-width: 460px){
  .product-info {
    width: 164px;
  }
  .product-image {
    height: 247px;
  }
  
  .product-image img {
    max-width: 164px;
    max-height: 247px;
   
  }
  .combinaciones{
    font-size: 12px;
  }
}

@import "styles/global.scss";

.main-nav-container {
  width: 100%;
  background: $color-white;
}

.navbar {
  width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-container {
  display: none;
  padding-left: 12px;
}

.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-container i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.navbar-menu {
  margin-bottom: 0;
}

.navbar-menu li,
.navbar-categories li {
  display: inline-block;
}

.navbar-menu li a,
.navbar-menu li span {
  display: block;
  color: $color-black-text;
  font-size: 20px;
  font-weight: 500;
  padding: 0px 20px;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navbar-menu li a.active,
.navbar-menu li span.active {
  color: $color-base;
}

.navbar-menu i,
.navbar-content i{
font-size: 20px;
}

.navbar-user {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  height: 50px;
}

.navbar-user li {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-user li div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  color: $color-black-text;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.navbar-icon {
  width: 40px;
}

.checkout-items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  left: 22px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $color-base;
  font-size: 12px;
  color: $color-white;
}

.navbar-categories {
  width: 100%;
  background-color: $color-white;
  border-width: 0.5px 0px 0.5px 0px;
  border-color: $color-base;
  border-style: solid;
}

.navbar-categories a {
  display: block;
  color: $color-base;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px;
}

.fs-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.pointer-active {
  pointer-events: auto;
}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: -400px;
  width: 400px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger-menu.active {
  left: 0;
  overflow-y: scroll;
}

.hamburger-close {
  position: absolute;
  top: 26px;
  left: 10px;
  padding: 10px;
  z-index: 1;
}

.hamburger-close i {
  font-size: 24px;
  color: $color-black-text;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.hamburger-menu-content {
  padding-top: 100px;
  width: 100%;
  height: 100%;
}

.menu-top-nav {
  padding-left: 20px;
  overflow-y: auto;
  padding-right: 10px;
}

.menu-top-nav li {
  display: block;
  position: relative;
  padding-right: 0;
}

.menu-top-nav li a {
  display: block;
  color: $color-black-text;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: solid 1px $color-gray-iron;
  cursor: pointer;
}

.menu-top-nav li.active {
  display: block;
  visibility: visible;
  opacity: 1;
}

.menu-top-nav li a.active {
  color: $color-base;
}

.menu-top-nav li a i {
  margin-left: 8px;
}

.hamburger-container:hover i {
  color: $color-gray-light;
}

.navbar-menu li a:hover,
.navbar-menu li a.active:hover {
  color: $color-gray-light;
}

.navbar-categories li a:hover {
  color: $color-gray-iron;
}

.navbar-user li div:hover {
  color: $color-gray-light;
}

.hamburger-close:hover i {
  color: $color-gray-light;
}

.menu-top-nav li a:hover,
.menu-top-nav li a.active:hover {
  color: $color-gray-light;
}

.top-nav-item-favourite{
  color: $color-base;
  padding: 9px 10px 9px 10px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid $color-base;
  border-radius: 16px;
  font-weight: 700;
  font-size: 12px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1532px) {
  .navbar-menu li a,
  .navbar-menu li span {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1405px) {
.navbar li a{
  padding: 0px 15px;
}

}

@media only screen and (max-width: 1299px) {
  .navbar-menu li a,
  .navbar-menu li span {
    font-size: 15px;
    padding: 0px 12px;
  }
  .navbar-menu i,
  .navbar-content i{
  font-size: 18px;
  padding: 0px 12px;
  }

  }

  @media only screen and (max-width: 1178px) {
    .navbar-menu li a,
    .navbar-menu li span {
      font-size: 13px;
      padding: 0px 10px;
    }
    .navbar-menu i,
    .navbar-content i{
    font-size: 16px;
    padding: 0px 11px;
    }
    .navbar-user li {
      padding-left: 5px;
      padding-right: 5px;
    }
    }

@media only screen and (max-width: 991px) {
  .main-nav-container {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  }

  .hamburger-container {
    display: block;
  }

  .navbar-menu,
  .navbar-categories {
    display: none;
  }

  .navbar-text {
    display: none !important;
  }

  .navbar-content {
    justify-content: right;
  }

  .navbar-content ul{
    padding-right: 20px;
  }

  .navbar-logo{
    margin-right: auto;
  }

  .navbar-menu li a,
.navbar-menu li span {
  font-size: 17px;
  padding: 0px 20px;
}
.navbar-menu i,
.navbar-content i{
font-size: 20px;
}
}
@media only screen and (max-width: 575px) {

  .hamburger-menu {
    left: -100%;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .hamburger-close {
    top: 14px;
    left: 4px;
  }

  .hamburger-close i {
    font-size: 20px;
  }

  .hamburger-menu-content {
    padding-top: 70px;
  }

  .menu-top-nav {
    padding-left: 15px;
  }
}

.navbar-logo{
  display: flex;
  justify-content: center;
  align-items: center;
}
@import "styles/global.scss";

.top-nav {
  width: 100%;
  background-color: $color-base;
}

.top-nav-content {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.top-nav-user-actions {
  text-align: right;
}

.top-nav-border-right {
  border-right: 1px solid $color-green-words;
}

.top-nav-item {
  color: $color-white;
  padding: 0 10px;
  cursor: pointer;
}

.top-nav-item:hover {
  color: $color-gray-iron;
}

@media only screen and (max-width: 767px) {
  .top-nav-user-actions,
  .top-nav-info {
    text-align: center;
    margin: 0px 0;
  }
}

@import "styles/global.scss";

.benefit {
  width: 100%;
  margin: 74px 0;
}

.benefit-row {
  padding-left: 15px;
  padding-right: 15px;
}

.benefit-col {
  padding-left: 0;
  padding-right: 0;
}

.benefit-item {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: solid 1px $color-base;
  padding-left: 30px;
  padding-right: 30px;
}

.benefit-col:last-child .benefit-item {
  border-right: none;
}

.benefit-icon i {
  font-size: 30px;
  color: $color-base;
}

.benefit-content {
  padding-left: 22px;
}

.benefit-content h6 {
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.benefit-content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  color: $color-gray-dark;
}

.benefit-col:hover {
  background-color: $color-gray-concrete;
}

@media only screen and (max-width: 991px) {
  .benefit {
    margin: 40px 0;
  }

  .benefit-item {
    border-bottom: solid 1px $color-base;
    border-right: none;
    padding-left: 15px;
    padding-right: 15px;
  }

  .benefit-col:last-child .benefit-item {
    border-bottom: none;
  }
}

@media only screen and (max-width: 767px) {
  .benefit-item {
    justify-content: flex-start;
  }
}
@import "styles/global.scss";

.main-slider {
  width: 100%;
  height: 100%;
}

.main-slider-img {
  width: 100%;
  height: 100%;
}

.main-slider-video {
  width: 100%;
  height: 400px;
}

.section {
  margin-bottom: 100px;
  margin-top: 100px;
}

.new-arrivals-sorting {
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 50px;
  overflow: visible;
  width: 100%;
}

.arrivals-grid-sorting {
  display: flex;
  justify-content: center;
  width: 100%;
}

.green-button.active {
  color: $color-white;
  background: $color-base;
}

.grid-sorting-button.active:hover {
  background: $color-base-hover !important;
}

.product-slider-container {
  margin-bottom: 34px;
  justify-content: center;
}

.info-container {
  text-align: center;
  color: $color-black;
  font-size: 56px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 80px;
  word-wrap: break-word;
}

.info-container ul li {
  margin-top: 30px;
}

.info-container img {
  width: 100%;
  height: auto;
}

.info-container a {
  color: $color-base;
}
.info-container a:hover {
  color: $color-base-hover;
}

.green-button {
  color: $color-base;
  border-color: $color-base;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 0px;
  background-color: transparent;
  width: 250px;
  height: 50px;
  justify-content: center;
  text-align: center;
}

.green-button:hover {
  color: $color-white;
  background-color: $color-base;
}

.maps-panoram {
  width: 100%;
  height: 570px;
}

.mapsInv {
  display: none;
}

.category-images img {
  width: 94%;
  height: 100%;
  object-fit: cover;
}
.img-altura{
  height: 24vw!important;
}
.category-images div {
  justify-content: center;
}

.column-flex {
  display: flex;
  width: 100%;
  padding: 10px 10px;
}

.img-button {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.img-button button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: calc(100% - 150px);
  height: calc(100% - 120px);
  background-color: $color-black;
  color: $color-white;
  font-size: 2vw;
  border-width: 3px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 0px;
  opacity: 0;
}

.carousel-button {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.carousel-button .cbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, -50%);
  width: 17%;
  height: 13%;
  background-color: $color-gray-dark;
  color: $color-white;
  font-size: 1vw;
  border-width: 3px;
  border-color: $color-white;
  border-style: solid;
  cursor: pointer;
  border-radius: 0px;
  opacity: 0.8;
  z-index: 1;
}

.img-button:hover button {
  opacity: 0.6;
}

.img-button:hover {
  filter: grayscale(100%);
}

.navbar-categories li {
  display: inline-block;
}

.navbar-categories {
  width: 100%;
  background-color: $color-white;
  border-width: 0.5px 0px 0.5px 0px;
  border-color: $color-base;
  border-style: solid;
}

.navbar-categories a {
  display: block;
  color: $color-base;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px;
}

.navbar-categories a:hover {
  color: $color-gray-iron;
}

.scroll-menu-arrow {
  padding: 10px;
  color: $color-black;
  font-size: 18px;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.button-group{
  align-items: center;
  }
@media only screen and (max-width: 1100px) {
  .maps-panoram {
    height: 500px;
  }
}

@media only screen and (max-width: 991px) {
  .column-flex {
    padding: 17px 0px;
  }

  .img-button button {
    font-size: 7vw;
  }

  .img-altura {
    height: 50vw!important;
  }

  .carousel-button .cbutton {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .maps-panoram {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .info-container {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 50px;
    word-wrap: break-word;
  }

  .maps-panoram {
    height: 330px;
  }
}

@media only screen and (max-width: 576px) {
  .arrivals-grid-sorting {
    display: flex;
    flex-direction: column;
  }

  .arrivals-grid-sorting li {
    margin-bottom: 10px;
  }

  .info-container {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 50px;
    word-wrap: break-word;
  }

  .maps-panoram {
    height: 220px;
  }
}

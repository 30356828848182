@import "styles/global.scss";

.footer {
  background-color: $color-white;
  color: $color-black;
  font-size: 14px;
}

.footer-top {
  padding: 60px 0;
}

.footer-column .logo-container a span:first-child,
.footer-column .logo-container a span:last-child {
  color: $color-white;
}

.footer-column .logo-container a:hover span {
  color: $color-base;
}

.footer-column .title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: $color-black;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: $color-base;
  color: $color-white;
  font-size: 18px;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  transition: 0.3s;
}

.copyright{
  display: flex;
  background-color: $color-black;
  color: $color-white;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  font-size: 16px;
}

.copyright-wrap {
  border-top: 1px solid $color-base;
}

.copyright a {
  color: $color-white;

}

.footer-column ul li a,
.copyright a:hover {
  color: $color-base;
}

.social-links a:hover {
  background-color: $color-base;
  color: $color-white;
  text-decoration: none;
}

.footer-column ul li a:hover {
  color: $color-base-hover;
}

.newsletter-title{
  color: black;
font-size: 36px;
font-weight: 600;
line-height: 44px;
word-wrap: break-word
}
.newsletter-text{
  font-size: 14px;
  }

.email-input{
  border: 0;
  outline: 0;
  width: 340px;
}

.input-button-group{
  display: flex;
  align-items: center;
  text-align: center;
  border-width: 0.5px;
  border-color: $color-black;
  border-style: solid;
}

.input-group{
  display: flex;
  align-items: center;
}

.newsletter-btn{
  background-color: transparent;
  border-width: 0;
}
@media only screen and (max-width: 991px) {
  .footer-top {
    padding-top: 60px;
    padding-bottom: 0;
  }

  .footer-column {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .social-links {
    justify-content: flex-start;
  }

  .copyright{
    font-size: 15px;
  }

  .footer-column {
    padding-top: 20px;
  }

  
}

@media only screen and (max-width: 520px) {
  .newsletter-title{
    font-size: 28px;
    }

  .newsletter-text{
    font-size: 10.3px;
    }

    .email-input{
      width: 230px;
    }
}



@import "styles/global.scss";

button {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-weight: 500;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  line-height: 1.75;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

button span {
  color: $color-white;
  width: 100%;
}

.default-button {
  background-color: $color-base;
}

.disabled-button {
  background-color: $color-gray;
  cursor: default;
}

.default-button:hover {
  background-color: $color-base-hover;
}
